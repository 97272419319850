import Api from "./UMS/Api";
import User from "./UMS/User";
import Configuration from "./UMS/Configuration";

export default class UMS {
    constructor() {
        this.api = new Api;
        this.user = new User(this.api);
        this.configuration = new Configuration(this.api);
    }

    config(configKey, defaultValue) {
        return this.configuration.get(configKey, defaultValue);
    }


};