import axios from "axios";
require('promise.prototype.finally').shim();

export default class Api {
    constructor() {
        this.axios = this.createAxios();
    }

    setCsrfToken(token) {
        this.csrf_token = token;
        this.axios = this.createAxios();
    }

    get(controller, action, data, options) {
        data = data || {};
        data['controller'] = controller;
        data['action'] = action;
        data['no_cache'] = Math.floor(Math.random() * 1000000);

        options = options || {};
        options['params'] = data;
        let loadingStatus = options.loading_status !== false;
        loadingStatus && window.storage.commit('setLoading', true);
        return new Promise((resolve, reject) => {
            this.axios.get(window.endpoint, options)
                .then(response => resolve(response))
                .catch(error => {
                    this.handleError(error, reject);
                })
                .finally(() => loadingStatus && window.storage.commit('setLoading', false));
        });
    }

    getUrl(controller, action, data) {
        data = data || {};
        data['controller'] = controller;
        data['action'] = action;

        let options = Object.entries(data).map(item => item.join('=')).join('&');
        return `${window.endpoint}?${options}`;
    }

    post(controller, action, data, options = {}) {
        data = data || {};
        data['controller'] = controller;
        data['action'] = action;

        let loadingStatus = options.loading_status !== false;
        loadingStatus && window.storage.commit('setLoading', true);
        return new Promise((resolve, reject) => {
            this.axios.post(window.endpoint, data, options)
                .then(response => resolve(response))
                .catch(error => {
                    this.handleError(error, reject);
                })
                .finally(() => loadingStatus && window.storage.commit('setLoading', false));
        });
    }

    createAxios() {
        return axios.create({
            withCredentials: true,
            headers        : {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-TOKEN'    : this.csrf_token,
            },
        });
    }

    handleError(error, reject) {
        let response = error.response;
        let status = response.status;
        let data = response.data || {};
        let destination = {name: 'login'};
        let rawQuery = '';
        let route = window.router.match(window.location.pathname);

        if (status === 400) {
            window.storage.dispatch('addError', response.data);
        } else if (status === 401 && data.unauthenticated === 'yes') {
            if (window.location.pathname === '/login') throw error;
            if (route.fullPath !== '/' && route.fullPath !== '/login') {
                destination.query = {redirect: route.fullPath};
                rawQuery = `?redirect=${route.fullPath}`;
            }
            window.storage.dispatch('account/logoutCleanUp');
            // create an exception for the live view to prevent an reload loop
            if (window.location.host.match(/^localhost/)) {
                window.router.push(destination);
                return;
            }
            // go to login page if an 401 occurs
            // after reloading the page the parameter AUTHENTICATED will be set by Perl
            window.location = `/login${rawQuery}`;
        } else if (status === 403) {
            window.storage.dispatch('addError', 'general.error_forbidden');
        } else if (status === 404) {
            window.storage.dispatch('addError', 'general.error_not_found');
        } else if (status >= 500) {
            window.storage.dispatch('addError', 'general.error_internal');
        }
        reject(error);
    }
}