export default class Configuration {
    constructor(api) {
        this.api = api;
        this.data = {};
    }

    add(data){
        for (let type in data) {
            this.data[type] = data[type];
        }
    }

    load() {
        return this.api.get('app', 'configuration').then(response => this.data = response.data);
    }

    get(configurationKey, defaultValue = null) {
        let keys = configurationKey.split('.');
        let data = this.data;
        for(let key in keys){
            key = keys[key];
            if(key in data){
                data = data[key];
            } else{
                return defaultValue;
            }
        }
        return data;
    }
}